<template>
  <div class="main_content">
    <!--<div class="tips_box">欢迎使用！<span>卫健公社健康管理系统</span></div>-->
    <div class="title_box">
      <span></span>
      <h5>直播管理</h5>
    </div>
    <div class="line"></div>
    <div class="operate_box">
      <div class="search_box">
        <div class="input_box">
          <span style="width:90px">直播时间：</span>
          <el-date-picker value-format="yyyy-MM-dd HH:mm:ss" type="datetime" v-model="searchForm.livingStart"
            @change="getLiveList" clearable="" placeholder="直播时间">
          </el-date-picker>
        </div>
      </div>
    </div>
    <div class="tbale_box">
      <el-table :data="tableData" v-loading="loading" border style="width: 100%" height="100%">
        <el-table-column prop="theme" label="直播主题" />
        <el-table-column prop="userName" label="直播老师" />
        <el-table-column prop="livingStart" label="直播时间" />
        <el-table-column prop="watchTotal" label="观看直播总人数" />
        <el-table-column label="操作" width="60">
          <template slot-scope="scope">
            <el-button type="primary" icon="el-icon-info" circle @click="GetliveInfo(scope.row)"></el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageBox.pageNum"
      :page-sizes="[10, 20, 50, 100, 200, 300, 400]" :page-size="pageBox.pageSize"
      layout="total, sizes, prev, pager, next, jumper" :total="pageBox.total" />
    <!-- <new-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :page-size="pageBox.pageSize"
      :current-page="pageBox.pageNum" layout="total,sizes, prev, pager, next" :total="pageBox.total">
    </new-pagination> -->
    <!-- 查看详情-->
    <el-dialog :visible.sync="infoDialog" width="40%" :close-on-click-modal="false" :close-on-press-escape="false"
      class="set_dialog_style">
      <div slot="title" class="dialog_title">
        <span></span>直播明细
      </div>
      <live-info v-if="infoDialog" :liveBaseInfo="liveBaseInfo"></live-info>
    </el-dialog>
  </div>
</template>

<script>
import liveInfo from "./components/liveInfo";
import api from '@/api/index'
export default {
  name: 'home',
  components: { liveInfo },
  data() {
    return {
      searchForm: {
        livingStart: '',
      },
      tableData: [],
      pageBox: {
        pageSize: 10,
        total: 0,
        pageNum: 1
      },
      infoDialog: false,
      liveBaseInfo: {},
      loading: true
    }
  },
  created() {
    this.getLiveList()
  },
  methods: {
    /* 一页显示多少条切换 */
    handleSizeChange(val) {
      this.pageBox.pageSize = val;
      this.getLiveList();
    },
    /* 分页切换 */
    handleCurrentChange(val) {
      this.pageBox.pageNum = val;
      this.getLiveList();
    },
    //获取直播列表
    async getLiveList() {
      this.loading = true;
      try {
        const res = await api.getLiveLists(this.searchForm, this.pageBox)
        if (res.code == 200 && res.rows) {
          let list = res.rows.map(item => {
            var arr = item.theme.split("/");
            item.theme = arr[1]
            item.userName = arr[0]
            return item
          })
          this.tableData = list
          this.pageBox.total = res.total
        } else {
          this.tableData = []
        }
      } catch (e) {
        console.error("Error fetching live list:", error);
        this.tableData = [];
      } finally {
        this.loading = false;
      }
    },
    //获取直播详情
    GetliveInfo(row) {
      this.liveBaseInfo = row
      this.infoDialog = true
    }
  }
}
</script>

<style scoped lang="less">
.el-pagination {
  text-align: center;
}
.main_content {
  //background-image: url("../../assets/images/banner.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;

  .tips_box {
    display: flex;
    align-items: center;
    height: 100%;
    font-size: 60px;
    color: #00b8b8;
    justify-content: center;
    padding-left: 250px;
    background-image: -webkit-linear-gradient(bottom, #3be7de, #00b8b8);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: YouSheBiaoTiHei;
  }
}
</style>
