<template>
  <div class="liveInfoBox">
    <div class="baseInfo">
      <div class="infoTips">
        <p>直播主题：{{ liveBaseInfo.theme }}</p>
        <p>直播老师：{{ liveBaseInfo.userName }}</p>
        <p>直播时间：{{ liveBaseInfo.livingStart }}</p>
        <p>观看总人数：{{ liveBaseInfo.watchTotal }}</p>
      </div>
      <div class="infoImg">
        <p>直播二维码</p>
        <img :src="liveCodeImg">
      </div>
    </div>
    <div style="text-align: right;margin-top: 10px;margin-right: 10px"><el-button type="primary"
        @click="exportLive">导出直播明细</el-button></div>
    <div class="tbale_box liveTable">
      <el-table :data="tableData" border style="width: 100%" height="100%">
        <el-table-column label="序号" type="index" />
        <el-table-column prop="userName" label="观看人" />
        <el-table-column prop="phoneNumber" label="手机号" />
        <el-table-column prop="userType" label="人员类型" />
        <el-table-column prop="watchTime" label="观看时长(分钟)" />
      </el-table>
    </div>
  </div>
</template>

<script>
import api from '@/api/index'
export default {
  props: ['liveBaseInfo'],
  name: "liveInfo",
  data() {
    return {
      tableData: [],
      liveCodeImg: ''
    }
  },
  created() {
    console.log(this.liveBaseInfo)
    this.getLiveCode(this.liveBaseInfo.liveStreamingId)
    this.getWatchUserList(this.liveBaseInfo.liveStreamingId)
  },
  methods: {
    //导出
    async exportLive() {
      let res = await api.getLiveExport(this.liveBaseInfo)
      let name = '直播数据'
      let blob = new Blob([res], { type: "xls" });
      let downloadElement = document.createElement("a");
      let href = window.URL.createObjectURL(blob); //创建下载的链接
      downloadElement.href = href;
      downloadElement.download = `${name}.xls`; //下载后文件名
      document.body.appendChild(downloadElement);
      downloadElement.click(); //点击下载
      document.body.removeChild(downloadElement); //下载完成移除元素
      window.URL.revokeObjectURL(href); //释放掉blob对象
      this.$message.success(`导出成功`);
    },
    //根据直播id获取直播二维码
    async getLiveCode(liveStreamingId) {
      let res = await api.getLiveCodeImg(liveStreamingId)
      if (res.code == 200 && res.data) {
        this.liveCodeImg = res.data
      }
    },
    //获取观看直播人员列表
    async getWatchUserList(liveStreamingId) {
      const formData = new FormData()
      formData.append('livingid', liveStreamingId);
      try {
        let res = await api.getWatchUserLists(formData)
        if (res.code == 200 && res.data) {
          if (res.data.external_users && res.data.external_users.length > 0) {
            res.data.external_users.forEach(item => {
              item.watchTime = (item.watchTime / 60).toFixed(1)
              item.userName = item.residentsInfos.trueName
              item.userType = '外部成员'
              item.phoneNumber = item.residentsInfos.phoneNumber
            })
          }
          if (res.data.internal_users && res.data.internal_users.length > 0) {
            res.data.internal_users.forEach(item => {
              item.watchTime = (item.watchTime / 60).toFixed(1)
              item.userName = item.residentsInfos.trueName
              item.userType = '企业成员'
              item.phoneNumber = item.residentsInfos.phoneNumber
            })
          }
        }
        this.tableData = res.data.external_users.concat(res.data.internal_users)
      } catch (e) {

      }
    }
  }
}
</script>

<style scoped lang="less">
.liveInfoBox {
  width: 100%;

  .baseInfo {
    width: 100%;
    display: flex;

    .infoTips {
      flex: 1;

      p {
        line-height: 36px;
      }
    }

    .infoImg {
      text-align: center;

      img {
        width: 120px;
        height: 120px;
        margin-top: 8px;
      }
    }
  }

  .liveTable {
    height: 380px;
  }
}
</style>
